import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import get from 'lodash.get';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/happy-family.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { FAMILY_BASED_IMMIGRATION_TESTIMONIALS } from '../data/family-based-immigration/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Family-Based Immigration USA | Visa & Green Card | Pandev Law',
  description:
    'Searching for a trusted and experienced Family-Based Immigration lawyer? Pandev Law will guide you through the Green Card sponsorship process. Talk to us now!',
  keywords:
    'family-based immigration,family immigration,parents visa USA,family visa USA,family green card',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Family-Based Immigration USA | Visa & Green Card | Pandev Law",
    "description": "Searching for a trusted and experienced Family-Based Immigration lawyer? Pandev Law will guide you through the Green Card sponsorship process. Talk to us now!",
    "url": "https://www.pandevlaw.com/family-based-green-card-immigration",
    "image": "https://pandevlaw.com/img/happy-family.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/family-based-green-card-immigration/"
  },
  "headline": "Family-Based Immigration",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function FamilyBasedGreenCardImmigration({ location, data }) {
  const category = get(data, 'category', null);

  const title = 'Family-Based Immigration';
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title={title} img={heroImg} imgAlt={title}>
        <p className="text-lead mb-5 mb-md-6">
          Our immigration law firm has the expertise and necessary know-how to develop
          your customized family-based immigration solution, tailored to your specific
          needs. We have years of experience in all areas of family-based immigration,
          including family-sponsored green cards and fiance visas. We use our years of
          experience to guide you through the entire process of applying for and obtaining
          a family-based green card for you, your spouse, parent, or other family member.
          We represent you through all phases of your family-based immigration journey,
          from preparing your initial application for submission, including all forms and
          supporting documents, to advising you during every step of the process,
          including your immigration interview and any requests for additional evidence
          issued by the government. Our family visa USA services include:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Family-Based Immigration Testimonials"
        testimonials={FAMILY_BASED_IMMIGRATION_TESTIMONIALS}
      />

      <div className="section pt-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/happy-black-family.jpg"
                alt="Family-Based Green Card"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5">
                <strong>Family-Based Green Card</strong>
              </h2>
              <p className="text-body mb-6">
                Our immigration law firm has experience with all family-based green card
                applications. We have helped countless US citizens and permanent residents
                sponsor their spouse, children, parents, and siblings for a
                family-sponsored green card. We have successfully represented families
                through both the adjustment of status as well as the consular process for
                a family visa. We have the necessary knowledge and understanding of the
                family-based green card process to help you sponsor your foreign national
                relatives. Specifically, our family-based green card services include:
              </p>
              <div className="accordion mb-5" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>Immediate Relative Green Cards</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The immediate relative family-based green card category is reserved
                      for U.S. citizens who wish to bring their immediate family members
                      to the United States. The immediate relative category is the fastest
                      family-sponsored green card path to permanent residence in the
                      United States because there is no cap on the number of immediate
                      relative green cards issued annually by the government. Apart from
                      the standard USCIS processing times that every petition has to go
                      through, there is no additional waiting period for the immediate
                      relative family-based immigration category. However, the immediate
                      relative family-based green card category is reserved only for the
                      following relatives of U.S. citizens:
                    </p>
                    {/* <!--{' '}<a href="" target="_blank" rel="noopener noreferrer"></a>{' '}--> */}
                    <ul>
                      <li>
                        <h4>
                          {' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-through-marriage/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Green Card for Spouse
                          </a>{' '}
                        </h4>
                        <p className="my-5">
                          Spouses of US citizens are considered immediate relatives. This
                          means there is no cap on the amount of green cards the
                          government may issue in any given year to{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-for-husband-of-us-citizen/"
                            target="_blank"
                            rel="noopener noreferrer">
                            husbands
                          </a>{' '}
                          or{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/how-to-apply-for-a-green-card-for-my-wife/"
                            target="_blank"
                            rel="noopener noreferrer">
                            wives
                          </a>{' '}
                          US citizens. Our family-based immigration attorneys have
                          extensive experience with the{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-marriage-process/"
                            target="_blank"
                            rel="noopener noreferrer">
                            marriage-based green card process
                          </a>{' '}
                          , from preparing all{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-through-marriage-needed-documents/"
                            target="_blank"
                            rel="noopener noreferrer">
                            necessary documents
                          </a>{' '}
                          for filing to representing couples at their{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-marriage-based-interview/"
                            target="_blank"
                            rel="noopener noreferrer">
                            green card interview
                          </a>{' '}
                          . We have successfully represented couples in many nonstandard
                          cases, for example where the couple has lived apart for many
                          years or lacks proof that they entered their{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/proving-a-bona-fide-marriage/"
                            target="_blank"
                            rel="noopener noreferrer">
                            marriage in good faith
                          </a>{' '}
                          . A niche practice of our law firm is working with{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/same-sex-marriage-green-card/"
                            target="_blank"
                            rel="noopener noreferrer">
                            same-sex couples
                          </a>{' '}
                          . Our attorneys are proud of our long track record helping
                          successfully guide same-sex couples through the{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/marriage-green-card-timeline/"
                            target="_blank"
                            rel="noopener noreferrer">
                            marriage-based green card process
                          </a>{' '}
                          .
                        </p>
                      </li>
                      <li>
                        <h4>
                          {' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-for-child/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Green Card for Unmarried Children Under 21
                          </a>{' '}
                        </h4>
                        <p className="my-5">
                          The immediate relative family-based immigration category allows
                          U.S. citizens to petition for their unmarried children under the
                          age of 21. As an immediate relative, children of US citizens
                          under the age of 21 are not subject to extensive waiting times
                          due to visa category backlogs. Our family visa USA attorneys
                          have helped unite many parents with their children through the
                          green card process. We have significant experience with complex
                          cases, having helped many adoptive parents and step parents
                          sponsor their children for a family-sponsored green card.
                        </p>
                      </li>
                      <li>
                        <h4>
                          {' '}
                          <a
                            href="https://www.pandevlaw.com/blog/green-card-for-parents/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Green card for Parents of US Citizens
                          </a>{' '}
                        </h4>
                        <p className="my-5">
                          U.S citizens may sponsor their parents for a family-based green
                          card via the immediate relative category. Our family-based
                          immigration lawyers can help you sponsor your{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/apply-for-mother-green-card/"
                            target="_blank"
                            rel="noopener noreferrer">
                            mother
                          </a>{' '}
                          or{' '}
                          <a
                            href="https://www.pandevlaw.com/blog/apply-for-father-green-card/"
                            target="_blank"
                            rel="noopener noreferrer">
                            father
                          </a>{' '}
                          for a family-sponsored green card. Our team has helped obtain a
                          green card for parents of US citizens, including adoptive
                          parents and step parents. We have successfully guided numerous
                          families through the entire family-sponsored immigration
                          process, including successfully preparing parents for their
                          immigration interview which oftentimes is a major cause of
                          anxiety for the applicants.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseTwo">
                      <strong>F1 First Preference Green Cards</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      The F1 first preference category is reserved for unmarried{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/green-card-parents-petition-son/"
                        target="_blank"
                        rel="noopener noreferrer">
                        sons
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/how-to-apply-for-green-card-for-my-daughter/"
                        target="_blank"
                        rel="noopener noreferrer">
                        daughters
                      </a>{' '}
                      of U.S. citizens who are over the age of 21. The F1 category has an
                      annual cap of 23,400 green cards per year. This means that the
                      government may only issue this number of F1 green cards in any year.
                      Due to the number of F1 green card applications exceeding the
                      available quota, there are significant wait times and backlogs in
                      this family-based green card category. Our family-based immigration
                      attorneys have helped many parents sponsor their adult children for
                      an F1 green card. Given the backlogs and wait times in the F1 green
                      card category, our attorneys devise custom tailored strategies to
                      guide your family through the F1 green card process. We have the
                      necessary know-how and experience to help you with every phase of
                      your F1 family-based green card journey.
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseThree">
                      <strong>F2A Second Preference Green Cards</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      The F2A second preference family-based green card category is
                      reserved for{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/how-to-apply-for-a-green-card-for-my-wife/"
                        target="_blank"
                        rel="noopener noreferrer">
                        spouses
                      </a>{' '}
                      and unmarried children under the age of 21 of lawful permanent
                      residents. It is a subcategory of the F2 second preference category
                      for which the U.S. government grants 114,200 family visas annually.
                      Due to the numerical cap on the number of green cards issued in the
                      F2A category, there may be backlogs and longer wait times for these
                      cases. Our family visa USA lawyers have helped numerous permanent
                      residents sponsor their spouses and children for a green card. With
                      cases in the F2A category it is particularly important to have a
                      well-crafted immigration strategy because oftentimes the permanent
                      resident sponsor may qualify for naturalization, which could move
                      the case from the F2A to the immediate relative category. Our
                      immigration law firm can help you develop the best strategy for you
                      and your family and to guide you through the F2A family-sponsored
                      green card process.
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFour"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseFour">
                      <strong>F2B Second Preference Green Cards</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFour"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFour"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      The F2B second preference category is reserved for unmarried sons
                      and daughters of lawful permanent residents who are over the age of
                      21. The F2B family-based green card category receives only 23% of
                      the annual 114,200 green cards issued in the F2 second preference
                      category. This creates significant wait times and backlogs in the
                      F2B category. Our family-based immigration lawyers have helped many
                      permanent resident parents navigate the complexities of the F2B
                      family-sponsored green card process. Our attorneys can devise a
                      comprehensive immigration solution for you and your family to help
                      navigate you through your F2B green card application.
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingFive">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFive"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseFive">
                      <strong>F3 Third Preference Green Cards</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFive"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFive"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      The F3 third preference category is reserved for married sons and
                      daughters of U.S. citizens who are over the age of 21. The F3
                      family-based immigration category has an annual cap of 23,400 green
                      cards. Because of the many applications received in this family visa
                      category, there are significant backlogs and long wait times for F3
                      green cards. Our family-based immigration attorneys have experience
                      representing families through the F3 green card process and have the
                      requisite expertise to help devise your custom tailored F3
                      family-sponsored green card strategy.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="corporateLawHeadingSix">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseSix"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseSix">
                      <strong>F4 Fourth Preference Green Cards</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseSix"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingSix"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      The{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/green-card-for-siblings/"
                        target="_blank"
                        rel="noopener noreferrer">
                        F4 fourth preference family-based green card
                      </a>{' '}
                      category is reserved for{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/can-i-sponsor-my-brother-for-a-green-card/"
                        target="_blank"
                        rel="noopener noreferrer">
                        brothers
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.pandevlaw.com/blog/can-i-sponsor-my-sister-for-green-card/"
                        target="_blank"
                        rel="noopener noreferrer">
                        sisters
                      </a>{' '}
                      of US citizens. Our family visa USA attorneys have helped US
                      citizens petition green cards for their siblings, half-siblings,
                      step siblings, and adopted siblings. We have successfully
                      represented families through complex F4 family-based immigration
                      cases in which the government doubted the sibling relationship and
                      created significant obstacles before approving the cases. We have
                      the experience and knowledge of the relevant family-based
                      immigration rules to devise the best immigration strategy for you
                      and your siblings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/family-based-immigration-fiance.jpg"
                alt="Family-Based Immigration - Fiance Visa USA"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                <strong>
                  {' '}
                  <a
                    href="https://www.pandevlaw.com/blog/fiance-visa-usa/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Fiance Visa USA
                  </a>{' '}
                </strong>
              </h2>
              <h3 className="text-title mb-5">
                <strong>K-1 Fiance Visa</strong>
              </h3>
              <p className="text-body mt-4 mb-5">
                Our family-based immigration team has extensive experience with the{' '}
                <a
                  href="https://www.pandevlaw.com/blog/fiance-visa-process-the-complete-guide/"
                  target="_blank"
                  rel="noopener noreferrer">
                  fiance visa process
                </a>{' '}
                . US citizens may sponsor their fiance for a K-1 fiance visa which allows
                the fiance to enter the United States and marry the US citizen sponsor.
                Once the couple is married in the United States, they apply for a green
                card. Although the K-1 fiance visa itself is not a green card, it is often
                the right first step in a couple’s family-based immigration journey to the
                United States. Our family-based immigration attorneys can help advise you
                on whether the K-1 fiance visa is the best choice for you and your foreign
                national fiance.
                <br />
                <br />
                Our family visa lawyers have helped many couples navigate the{' '}
                <a
                  href="https://www.pandevlaw.com/blog/fiance-visa-timeline/"
                  target="_blank"
                  rel="noopener noreferrer">
                  K-1 fiance visa process
                </a>{' '}
                . Strategic immigration planning is critical for the K-1 fiance visa
                because of the numerous steps and strict requirements in the application
                process. After obtaining a K-1 fiance visa at a US embassy or consulate,
                you must marry within 90 days of your fiance’s entry into the United
                States. Thereafter, you must file an adjustment of status application with
                USCIS to obtain a green card. It is important to have a well-planned
                strategy in place and to anticipate common obstacles in the process before
                embarking on the K-1 fiance visa journey. Our family visa USA lawyers have
                helped numerous couples navigate the K-1 fiance visa process, from filing{' '}
                <a
                  href="https://www.pandevlaw.com/blog/fiance-visa-requirements-what-documents-are-needed-for-fiance-visa/"
                  target="_blank"
                  rel="noopener noreferrer">
                  all necessary documents
                </a>{' '}
                to preparing clients for the{' '}
                <a
                  href="https://www.pandevlaw.com/blog/fiance-visa-interview-questions-documents-process/"
                  target="_blank"
                  rel="noopener noreferrer">
                  K-1 visa interview
                </a>{' '}
                . We can help devise a K-1 visa strategy tailored to you and your fiance’s
                needs and help guide you through each step of the process until your
                fiance receives a family-based green card.
              </p>
              <h3 className="text-title mb-5">
                <strong>K-2 Visa for Children of K-1 Fiance</strong>
              </h3>
              <p className="text-body mt-4 mb-5">
                K-2 visas are issued to unmarried children under the age of 21 of K-1 visa
                applicants. The K-2 family visa process allows your fiance’s children to
                also enter the United States and obtain a green card once you and your
                fiance are married. The K-2 family visa process protects family unity by
                not separating minor children from their parents. It is critical for the
                K-2 visa applicant child to be under the age of 21 and to remain unmarried
                throughout the immigration process. Our family-based immigration lawyers
                have helped numerous children of K-1 visa applicants through the K-2 visa
                process. Devising a sound immigration plan prior to embarking on the K-2
                visa journey is critical. Our family visa USA team has the necessary
                know-how and experience to help guide you through this process to ensure
                that you and your children can immigrate to the United States together.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/family-based-immigration-removal-of-conditions.jpg"
                alt="Family-Based Immigration - Removal of Conditions"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                {' '}
                <a
                  href="https://www.pandevlaw.com/blog/removal-of-conditions-uscis-green-card/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Removal of Conditions
                </a>{' '}
              </h2>
              <p className="text-body mb-5">
                If you have been married to your US citizen spouse for less than 2 years
                when your marriage-based green card is approved, you will receive a
                conditional green card valid for 2 years. Within 90 days from the end of
                the 2-year conditional green card period, you must file a petition to
                remove conditions with USCIS to receive an unconditional 10-year
                family-based green card. In your petition to remove conditions, you must
                prove to USCIS that you entered into and continue to be in a good faith
                marriage with your US citizen spouse. USCIS requires this additional step
                for recently married couples to prevent fraudulent marriages entered into
                to obtain a green card. Our family visa attorneys have extensive
                experience with the removal of conditions process. We can help you prepare
                the petition and all relevant supporting evidence in a way that
                facilitates USCIS’s decision. In many cases, if the removal of conditions
                petition is well prepared, USCIS may waive the interview for the couple.
                If your case does require an interview, our family-based immigration
                attorneys can prepare you for the interview process and represent you at
                your removal of conditions interview.
              </p>
              <h3 className="text-title mb-5">
                <strong>Jointly Filed Removal of Conditions Petition</strong>
              </h3>
              <p className="text-body mb-6">
                If you are still married to your US citizen spouse at the end of your
                2-year conditional green card period, you must jointly file your petition
                to remove conditions with your US citizen spouse. This means that both you
                and your US citizen spouse through whom you obtained your family-based
                green card must sign the petition. If USCIS requests that you attend an
                interview, both you and your US citizen spouse will be required to be in
                attendance. Our family-based immigration attorneys have helped countless
                couples through the jointly filed removal of condition process. We have
                successfully represented many couples in complex removal of conditions
                cases, such as those where the couple are still married but separated and
                no longer living together, or those where the couple forgot to timely file
                the petition and must file outside of the mandatory 90-day filing window.
                Our family visa lawyers can review the particular circumstances of your
                case and devise a successful strategy for you to obtain an unconditional
                10-year green card even if your case has unusual circumstances.
              </p>
              <h3 className="text-title mb-5">
                <strong>Waiver of Joint Filing Requirement</strong>
              </h3>
              <p className="text-body mb-6">
                If you are no longer married to your US citizen spouse through whom you
                obtained your 2-year conditional green card, you may request a waiver of
                the joint filing requirement. In such cases, you may file your petition to
                remove conditions on your own. You may do so before, during, or after the
                90-day filing period. In order to qualify for the waiver, you will have to
                demonstrate that you meet one or more of the following requirements:
              </p>
              <ol>
                <li className="mb-5">
                  You entered into a good faith marriage with your US citizen spouse.
                  However, the marriage has ended{' '}
                  <a
                    href="https://www.pandevlaw.com/blog/renew-marriage-based-green-card-after-divorce/"
                    target="_blank"
                    rel="noopener noreferrer">
                    due to divorce
                  </a>{' '}
                  or the passing of your US citizen spouse.
                </li>
                <li className="mb-5">
                  You entered into a good faith marriage with your US citizen spouse.
                  However, you or your child were subject to abuse.
                </li>
                <li className="mb-5">
                  Termination of your conditional permanent residence status would cause
                  you extreme hardship.
                </li>
              </ol>
              <p className="text-body mb-6">
                Our family-based immigration lawyers have the requisite experience and
                know-how to help you apply for a waiver of the joint filing requirement.
                We have successfully represented individuals through the waiver of joint
                filing process and have created immigration strategies that have allowed
                our clients to retain their family-based green card although they have
                separated from their US citizen spouse. Our family visa USA team will
                review the circumstances of your case and devise the best strategy for you
                to obtain a 10-year unconditional green card.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Family-Based Immigration Testimonials"
        testimonials={FAMILY_BASED_IMMIGRATION_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default FamilyBasedGreenCardImmigration;

export const query = graphql`
  query FamilyBasedGreenCardImmigrationQuery {
    category: wpCategory(slug: { eq: "family-based-immigration" }) {
      ...BlogLandingHeroPost
    }
  }
`;
