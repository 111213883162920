export const FAMILY_BASED_IMMIGRATION_TESTIMONIALS = [
	{
		text: 'I went to Adrian for an I-751 RFE and 6 months later I am a U.S. citizen! ... Adrian created a custom strategy tailoring to our not so traditional case. We all worked hard as a team and trusted Adrian as a leader. The Saturday before my Monday interview, he called to inform us of how interviews have happened across the US. Nowhere is the same these days. We were uber-prepared and accomplished the unexpected. ',
		name: 'Tino Nguyen Dinh',
		stars: 5,
	},
	{
		text: "I cannot say enough good things about Adrian and his team. The green card process can be a very stressful one, and you constantly feel on edge because you want to make sure every step is done correctly. Pandev Law takes the guesswork out of everything. They guided us through every step, answered every question we had and made sure we were 100% prepared for our interview. And because of them, my husband was approved for his green card! They truly had our best interest at heart and took incredible care of us. If you're looking for an immigration lawyer, look no further. Adrian and his team are absolutely amazing! Thank you guys, so much! : )\r",
		name: 'Amanda darchiville',
		stars: 5,
	},
	{
		text: 'I can’t thank Adrian and his team enough! I reached out to Adrian for a 30 mins consultation regarding my case, and just like that, in a few months I got my family based green card. My family and I are so happy and grateful we made the right choice to use Pandev Law. Highly recommend Adrian, he was very thorough and generous. 5 stars is just not enough.\n',
		name: 'Andrine Campbell',
		stars: 5,
	},
	{
		text: "This is probably the best law firm I have had the privilege to work with. Adrian and his team helped my husband and I with his immigration after we go married. Our last lawyers were not good and if it wasn't for Adrian and his team he would've had to go back to Australia. But because of their hard work and expert advice we got his Green Card approved and he is able to stay. I would HIGHLY recommend this law firm.",
		name: 'Charlie Jones',
		stars: 5,
	},
	{
		text: 'Adrian provided me and my husband the best professional and compassionate service of any attorney we have ever had. He is LGBT friendly and would be the only immigration attorney I recommend to combat today’s difficult policies. We had a quick green card approval through his hard work and dedication. This man actually cares about his clients. Thank you Adrian!!!',
		name: 'Daniel Levine',
		stars: 5,
	},
	{
		text: 'Adrian Pandev is the ultimate professional. He is by far one of the smartest and most meticulous people that I have ever met. I wholeheartedly recommend him and the entire team at Pandev Law. He filed a family based petition on my behalf and my entire process took less than 90 days from start to finish. Best money that I ever spent!',
		name: 'Anvon Ferguson',
		stars: 5,
	},
	{
		text: 'We had a very positive experience with the team at Pandev Law. Our application process and interview for a marriage based green card went more smoothly than we ever could have imagined thanks to Pandev Law. We highly recommend for any immigration needs.\r',
		name: 'Zach Sowinski',
		stars: 5,
	},
	{
		text: "One of the best immigration lawyers around. He is very helpful and polite, knowledgeable and up To date and most importantly, he really cares about every client. I thank Adrian so much for helping me successfully get my marriage based green card. My husband and I couldn't be happier!",
		name: 'Sofia Lotscher',
		stars: 5,
	},
	{
		text: "My wife and I have been together for 6 years and during that length of time I have bee back and forth with USCIS on getting approval for my permanent resident and getting no where. I went on Google in search of an immigration attorney and came across Pandev Law. Based in the reviews I deside to try this lawyer to assist me. I was never disappointed, this was one of the best decisions I have ever made. When an attorney give an advice and gave his honest opinion whether it's what you want to hear or not, then you know he/ she is a very good one. Mr Pandev, I want to thank you from the bottom of my heart for helping me and my family through our deepest time of need. I received our permanent resident card in the mail for me and my 2 children and it is valid for 10 years. You are one of the realest and best immigration attorney I have ever met. You are true to word and will forever be greatly appreciated for your job well done. Thank you",
		name: 'Dave Walker',
		stars: 5,
	},
];
